<template>
  <div v-if="is_auth && user.role === 'admin'" class="sidebar">
    <div class="sidebar__container">
      <router-link
        class="sidebar__link"
        active-class="_active"
        v-for="(item, i) in navigation"
        :key="i"
        :to="{ name: item.route }"
      >
        <el-tooltip
          effect="light"
          :open-delay="1000"
          :content="item.label"
          placement="right"
        >
          <div class="sidebar__icon-container">
            <v-icon :name="item.icon" width="36" />
          </div>
        </el-tooltip>
      </router-link>
    </div>
  </div>
</template>

<script>
import VIcon from "@/components/icon/v-icon";
import { mapGetters } from "vuex";
export default {
  name: "v-sidebar",
  components: { VIcon },
  data: () => ({
    navigation: [
      {
        label: "Склад",
        route: "admin-store",
        icon: "icon_store",
      },
      {
        label: "Отслеживание заказов",
        route: "admin-orders",
        icon: "icon_delivery",
      },
      {
        label: "Конструктор писем",
        route: "admin-mail",
        icon: "icon_letter",
      },
      {
        label: "Управление контентом",
        route: "admin-management",
        icon: "icon_management",
      },
    ],
  }),

  computed: {
    ...mapGetters({
      is_auth: "auth/is_auth",
      user: "auth/user",
    }),
  },
};
</script>
