import axios from "../api";

export default {
  namespaced: true,

  state: {
    cart: {},
    new_cart: {},
    order_code: "",
    delivery_offices: [],
    packing_cost: 0,
    chlenskiy_vznos: 0,
    vstupitelniy_vznos: 0,
    delivery_cost: 0,
    call_success: false,
  },
  getters: {
    cart: (state) => state.cart,
    new_cart: (state) => state.new_cart,
    order_code: (state) => state.order_code,
    delivery_offices: (state) => state.delivery_offices,
    packing_cost: (state) => state.packing_cost,
    chlenskiy_vznos: (state) => state.chlenskiy_vznos,
    vstupitelniy_vznos: (state) => state.vstupitelniy_vznos,
    delivery_cost: (state) => state.delivery_cost,
    call_success: (state) => state.call_success,
  },
  mutations: {
    UPDATE_CART: (state, payload) => {
      state.cart = payload;
    },
    UPDATE_NEW_CART: (state, payload) => {
      state.new_cart = payload;
    },
    UPDATE_ORDER_CODE: (state, payload) => {
      state.order_code = payload;
    },
    UPDATE_DELIVERY_OFFICES: (state, payload) => {
      state.delivery_offices = payload.map((el) => {
        return {
          ...el,
          value: `${el?.name} (${el?.location?.address_full}) Часы работы: ${el?.work_time}`,
          html: true,
        };
      });
    },
    UPDATE_PACKING_COST: (state, payload) => {
      state.packing_cost = payload.packing_cost;
      state.chlenskiy_vznos = payload.chlenskiy_vznos;
      state.vstupitelniy_vznos = payload.vstupitelniy_vznos;
    },
    UPDATE_DELIVERY_COST: (state, { total_sum }) => {
      state.delivery_cost = total_sum;
    },
    CLEAR_CART: (state) => {
      state.cart = {};
    },
    UPDATE_CALL_SUCCESS: (state) => {
      state.call_success = !state.call_success;
    },
  },
  actions: {
    async createCart({ commit }, params) {
      const response = await axios.post("cart/create/", params);
      await commit("UPDATE_CART", response.data);
    },
    async getCart({ commit }, params) {
      const response = await axios.post("cart/content/", params);
      await commit("UPDATE_CART", response.data);
    },
    async checkCart({ commit }, params) {
      const response = await axios.post("cart/check/", params);
      await commit("UPDATE_CART", response.data);
    },
    async addProduct({ commit }, params) {
      const response = await axios.post("cart/add_item/", params);
      await commit("UPDATE_CART", response.data);
    },
    async removeProduct({ commit }, params) {
      const response = await axios.post("cart/remove_item/", params);
      await commit("UPDATE_CART", response.data);
    },
    async clearCart({ commit }, params) {
      const response = await axios.post("cart/clear/", params);
      await commit("UPDATE_CART", response.data);
    },

    async getPackingCost({ commit }, params) {
      const response = await axios.post("delivery/packing_cost/", params);
      await commit("UPDATE_PACKING_COST", response.data);
    },

    async getDeliveryCost({ commit }, params) {
      const response = await axios.post("delivery/cost_door/", params);
      await commit("UPDATE_DELIVERY_COST", response.data);
    },

    async createOrder({ commit }, params) {
      const response = await axios.post("order/create/", params);
      const { pay_form_link } = response.data;
      if (pay_form_link) {
        window.location.href = pay_form_link;
      } else {
        await commit("UPDATE_CALL_SUCCESS");
      }
    },

    async repeatOrder({ commit }, params) {
      const response = await axios.post("cart/repeat/", params);
      await commit("UPDATE_CART", response.data);
    },

    async getDeliveryOffices({ commit }, params) {
      const response = await axios.post("delivery/sdek_offices/", {
        search: params.query,
        ...params.data,
      });
      await commit("UPDATE_DELIVERY_OFFICES", response.data);
    },
  },
};
