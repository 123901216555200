<template>
  <div
    class="v-icon"
    v-html="icons[name]"
    :style="{
      color: color,
      width: `${width}px`,
      height: `${height || width}px`,
    }"
  />
</template>

<script>
import icons_collection from "./icons";

export default {
  name: "v-icon",

  data: () => ({
    icons: icons_collection,
  }),

  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "18",
    },
    height: {
      type: String,
    },
    color: {
      type: String,
      default: "inherit",
    },
  },
};
</script>
