import axios from "../api";

export default {
  namespaced: true,

  state: {
    orders: [],
    letters: [],
    letter_item: {},
    unread_letters: 0,
  },
  getters: {
    orders: (state) => state.orders,
    letters: (state) => state.letters,
    letter_item: (state) => state.letter_item,
    unread_letters: (state) => state.unread_letters,
  },
  mutations: {
    UPDATE_ORDERS(state, payload) {
      state.orders = payload;
    },
    UPDATE_LETTERS(state, payload) {
      state.letters = payload;
      if (payload.length) {
        state.unread_letters = payload.filter(
          (el) => el.opened === false
        ).length;
      } else {
        state.unread_letters = 0;
      }
    },
    UPDATE_LETTER_ITEM(state, payload) {
      state.letter_item = payload;
    },
  },
  actions: {
    async getOrders({ commit }) {
      const response = await axios.post("order/my_orders/");
      await commit("UPDATE_ORDERS", response.data);
    },
    async getLetters({ commit }, params) {
      const response = await axios.get("mail/list/", { params });
      await commit("UPDATE_LETTERS", response.data);
    },
    async getLetterItem({ commit }, params) {
      const response = await axios.get("mail/details/", { params });
      await commit("UPDATE_LETTER_ITEM", response.data);
    },
    async openLetter(_, params) {
      await axios.post("mail/open/", params);
    },
    async addBalance(_, params) {
      const response = await axios.post("order/add_rspv/", params);
      window.location.href = response.data.pay_form_link;
    },
    async exitMPO(_, params) {
      await axios.post("auth/exit_mpo/", params);
    },
  },
};
