<template>
  <div :class="['mobile-menu', `mobile-menu--${mode}`]">
    <button
      class="mobile-menu__burger"
      :class="{ _active: is_open_mobile_menu }"
      @click="changeMenu"
    >
      <span class="mobile-menu__burger-line" />
    </button>

    <div
      class="mobile-menu__container"
      :class="{ _active: is_open_mobile_menu }"
    >
      <!--      <v-input-->
      <!--        class="mobile-menu__search"-->
      <!--        v-model="form.search"-->
      <!--        v-bind="model.search"-->
      <!--      />-->
      <div class="mobile-menu__user">
        <!-- Авторизированный пользователь -->
        <template v-if="is_auth">
          <button
            class="mobile-menu__btn mobile-menu__btn--collapse"
            :class="{ _active: is_open_user }"
            @click="is_open_user = !is_open_user"
          >
            <span>Личный кабинет</span>
            <v-icon name="icon_arrow_down" width="24" />
          </button>
          <el-collapse-transition>
            <div class="mobile-menu__sub" v-show="is_open_user">
              <button
                class="mobile-menu__btn mobile-menu__btn--sub"
                :class="{ _active: $route.name === 'profile-user' }"
                @click="changeRoute('profile-user')"
              >
                <v-icon name="icon_user" width="24" />
                <span>Профиль</span>
              </button>
              <button
                class="mobile-menu__btn mobile-menu__btn--sub"
                :class="{ _active: $route.name === 'profile-orders' }"
                @click="changeRoute('profile-orders')"
              >
                <v-icon name="icon_user" width="24" />
                <span>Заказы</span>
              </button>
              <button
                class="mobile-menu__btn mobile-menu__btn--sub"
                :class="{ _active: $route.name === 'profile-letters' }"
                @click="changeRoute('profile-letters')"
              >
                <v-icon name="icon_letter" width="24" />
                <span>Уведомления</span>
              </button>
              <button
                class="mobile-menu__btn mobile-menu__btn--sub"
                @click="logout"
              >
                <v-icon name="icon_logout" width="24" />
                <span>Выйти</span>
              </button>
            </div>
          </el-collapse-transition>
        </template>

        <!-- Неавторизированный пользователь -->
        <template v-else>
          <button class="mobile-menu__btn mobile-menu__btn--blue" @click="auth">
            Войти в личный кабинет
          </button>
        </template>
      </div>
      <div class="mobile-menu__nav">
        <button
          class="mobile-menu__btn"
          :class="{ _active: $route.name === item.route }"
          v-for="item in navigation"
          :key="item.id"
          @click="changeRoute(item.route)"
        >
          {{ item.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VIcon from "@/components/icon/v-icon.vue";

export default {
  name: "mobile-menu",
  components: { VIcon },

  props: {
    mode: {
      type: String,
      default: "base", // base
    },
  },

  data: () => ({
    is_open_user: false,
    is_open_mobile_links: false,

    form: {
      search: "",
    },

    model: {
      search: {
        mode: "round",
        placeholder: "Поиск",
        icon: "icon_search",
      },
    },

    navigation: [
      { id: 1, label: "Каталог", route: "catalog" },
      { id: 2, label: "О нашем производстве", route: "about-production" },
      { id: 3, label: "О нашей форме", route: "about-form" },
      { id: 4, label: "Вопрос-ответ", route: "faq" },
      {
        id: 5,
        label: "Декларации и сертификаты соответствия",
        route: "declarations-and-certificates",
      },
      { id: 6, label: "Документы МПО", route: "mpo-documents" },
      {
        id: 7,
        label: "Стать участником целевой программы",
        route: "target-program",
      },
      // { id: 8, label: "Контакты", route: "contact-us" },
      { id: 9, label: "Рецепты", route: "recipes" },
      { id: 10, label: "Отзывы", route: "reviews" },
    ],
  }),

  computed: {
    ...mapGetters({
      is_auth: "auth/is_auth",
      is_open_mobile_menu: "utils/is_open_mobile_menu",
    }),
  },

  watch: {
    $route() {
      this.$store.commit("utils/UPDATE_OPEN_MOBILE_MENU", false);
    },
  },

  methods: {
    changeMenu() {
      this.$store.commit(
        "utils/UPDATE_OPEN_MOBILE_MENU",
        !this.is_open_mobile_menu
      );
    },

    changeRoute(name) {
      this.changeMenu();
      this.$router.push({ name });
    },

    auth() {
      this.changeMenu();
      this.$store.commit("auth/CALL_AUTH");
    },

    logout() {
      this.changeMenu();
      this.$store.dispatch("auth/logout");
      this.$store.commit("cart/CLEAR_CART");
      this.$router.push({ name: "main" });
    },
  },
};
</script>
