<template>
  <div
    class="app-modal__container"
    :class="{
      'animate__animated animate__fadeInRight animate__faster':
        modal_type === 'ModalAuthChoice' && is_changed,
      'animate__animated animate__fadeOutLeft animate__faster':
        modal_type !== 'ModalAuthChoice' && is_changed,
    }"
    v-auto-animate
  >
    <button class="app-modal__close" @click="$emit('close')">
      <v-icon name="icon_close" width="24" />
    </button>

    <h2 class="app-modal__title">Код подтверждения</h2>
    <p class="app-modal__text app-modal__text--center" style="margin-bottom: 0">
      Выберите способ получения кода подтверждения
    </p>
    <!--    <p class="app-modal__text" style="margin-bottom: 0">-->
    <!--      К сожалению подтверждение по <strong>СМС</strong> для сотовых операторов-->
    <!--      <strong>Украины</strong>, <strong>Беларусии</strong> и-->
    <!--      <strong>Казахстана</strong> временно не возможно, просим прощения за-->
    <!--      доставленные неудобства-->
    <!--    </p>-->
    <div class="app-modal__btn-group">
      <v-button @click="auth('sms')">По СМС</v-button>
      <v-button @click="auth('whatsapp')" mode="accent-transparent">
        Через Whatsapp
      </v-button>
    </div>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import VButton from "@/components/ui-kit/v-button";
import VIcon from "@/components/icon/v-icon";
import { mapGetters } from "vuex";

export default {
  name: "modal-auth-choice",
  components: { VIcon, VButton },
  mixins: [notifications],

  props: {
    is_changed: {
      type: Boolean,
      default: false,
    },
    modal_type: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters({
      phone: "auth/phone",
      registration_form: "auth/registration_form",
    }),
  },

  methods: {
    async auth(service) {
      try {
        if (this.registration_form) {
          const params = {
            service,
            ...this.registration_form,
          };
          await this.$store.dispatch("auth/registration", params);
        } else {
          const params = {
            phone: this.phone,
            service,
          };
          await this.$store.dispatch("auth/authorizationPhone", params);
        }
        this.$store.commit("auth/UPDATE_SERVICE", service);
        this.changeModal("ModalCode");
      } catch (e) {
        if (this.registration_form) {
          this.changeModal("ModalRegistration");
        }
        this.renderAsyncErrors(e);
      }
    },

    changeModal(modal) {
      this.$emit("changeModal", modal);
    },
  },
};
</script>
