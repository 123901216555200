import axios from "../api";

export default {
  namespaced: true,

  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getDocFile(_, params) {
      const response = await axios.get("docs/static/", {
        params,
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const linkUrl = document.createElement("a");

      linkUrl.download = params.static_name;
      linkUrl.href = downloadUrl;
      document.body.appendChild(linkUrl);
      linkUrl.click();
      document.body.removeChild(linkUrl);
      linkUrl.remove();
    },

    async getReport(_, params) {
      const response = await axios.get("docs/report/", {
        params,
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const linkUrl = document.createElement("a");

      linkUrl.download = 'Отчет РСПВ';
      linkUrl.href = downloadUrl;
      document.body.appendChild(linkUrl);
      linkUrl.click();
      document.body.removeChild(linkUrl);
      linkUrl.remove();
    },
  },
};
