import axios from "axios";
import store from "./index";
import { baseUrl } from "@/constant";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: baseUrl,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

api.interceptors.response.use(undefined, (e) => {
  return new Promise(() => {
    const { status } = e.response;
    if (status === 401) {
      store.dispatch("auth/logout");
    }
    throw e;
  });
});

export default api;
