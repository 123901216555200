<template>
  <el-dialog
    :visible.sync="show"
    :custom-class="styles"
    top="7vh"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
    :destroy-on-close="destroy_on_close"
  >
    <div ref="modal">
      <slot />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "v-modal",
  data: () => ({
    show: false,
  }),

  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
    is_error: {
      type: Boolean,
      default: false,
    },
    destroy_on_close: {
      type: Boolean,
      default: false,
    },
    custom_class: {
      type: String,
      default: "",
    },
  },

  watch: {
    is_show(value) {
      this.show = value;
    },
  },

  computed: {
    styles() {
      const style = `app-modal ${this.custom_class}`;
      if (this.is_error) {
        return style + " animate__animated animate__headShake";
      } else {
        return style;
      }
    },
  },
};
</script>
