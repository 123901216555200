<template>
  <button
    :class="[
      'v-button',
      `v-button--${mode}`,
      `v-button--${size}`,
      `v-button--${weight}`,
      { '_icon-right': iconRight, '_icon-left': iconLeft },
    ]"
    @click="$emit('click', $event)"
    :type="type"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "v-button",

  props: {
    mode: {
      type: String,
      default: "accent", // accent | accent-transparent | white | green | transparent | icon
    },
    size: {
      type: String,
      default: "m", // l | m | s
    },
    type: {
      type: String,
      default: "button", // native types
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String,
      default: "medium", // regular | medium | semibold | bold | extrabold | black
    },
  },
};
</script>
