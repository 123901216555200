<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__container">
        <div class="header__top-line">
          <div class="header__top-line-container container">
            <mobile-menu />
            <router-link class="header__logo" to="/">
              <img
                src="@/assets/images/main-logo.svg"
                width="169"
                height="43"
                alt="Логотип Создатель Энзимов"
              />
            </router-link>
            <nav class="header__nav">
              <v-button
                class="header__nav-route"
                :class="{ _active: $route.name === 'catalog' }"
                mode="transparent"
                icon-left
                @click="changeRoute('catalog')"
              >
                <v-icon name="icon_catalog" width="24" />
                Каталог
              </v-button>
            </nav>
            <!--            <v-input-->
            <!--              class="header__search"-->
            <!--              v-model="form.search"-->
            <!--              v-bind="model.search"-->
            <!--            />-->
            <v-user v-if="is_auth" class="header__user" />
            <v-button
              v-else
              class="header__auth"
              mode="transparent"
              icon-left
              @click="openAuth"
            >
              <v-icon name="icon_user" width="24" />
              Войти
            </v-button>
            <v-button
              class="header__cart"
              mode="icon"
              @click="$router.push({ name: 'cart' })"
            >
              <span v-if="cart_counter" class="header__cart-count">
                {{ cart_counter }}
              </span>
              <v-icon name="icon_cart" width="24" />
            </v-button>
          </div>
        </div>
        <nav class="header__bottom-line container">
          <v-button
            class="header__nav-link"
            :class="{ _accent: item.accent }"
            v-for="item in navigation"
            :key="item.id"
            size="s"
            :mode="$route.name === item.route ? 'accent' : 'white'"
            @click="changeRoute(item.route)"
          >
            {{ item.label }}
          </v-button>
        </nav>
      </div>
    </div>
    <v-modal
      :is_show="is_open_auth"
      :destroy_on_close="true"
      :is_error="is_error"
      @close="closeModal"
    >
      <keep-alive>
        <component
          v-if="is_open_auth"
          :is="modal_select"
          :modal_type="modal_changed"
          :is_changed="is_changed"
          @changeModal="changeModal"
          @error="errorHandler"
          @close="is_open_auth = false"
        />
      </keep-alive>
    </v-modal>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import ModalAuth from "@/components/modals/modal-auth";
import ModalAuthChoice from "@/components/modals/modal-auth-choice.vue";
import ModalRegistration from "@/components/modals/modal-registration";
import ModalCode from "@/components/modals/modal-code";
import MobileMenu from "@/components/blocks/mobile-menu.vue";
import VModal from "@/components/ui-kit/v-modal";
import VButton from "@/components/ui-kit/v-button";
import VIcon from "@/components/icon/v-icon";
import VUser from "@/components/ui-kit/v-user";
import VInput from "@/components/ui-kit/v-input.vue";

export default {
  name: "v-header",
  components: {
    ModalAuthChoice,
    VInput,
    MobileMenu,
    VUser,
    VIcon,
    VButton,
    VModal,
    ModalRegistration,
    ModalAuth,
    ModalCode,
  },

  data: () => ({
    form: {
      search: "",
    },

    model: {
      search: {
        mode: "round",
        placeholder: "Поиск",
        icon: "icon_search",
      },
    },

    navigation: [
      { id: 1, label: "О нашем производстве", route: "about-production" },
      { id: 2, label: "О нашей форме деятельности", route: "about-form" },
      { id: 3, label: "Вопрос-ответ", route: "faq" },
      {
        id: 4,
        label: "Декларации и сертификаты соответствия",
        route: "declarations-and-certificates",
      },
      { id: 5, label: "Документы МПО", route: "mpo-documents" },
      {
        id: 6,
        label: "Стать участником целевой программы",
        route: "target-program",
      },
      // { id: 7, label: "Контакты", route: "contact-us" },
      { id: 8, label: "Рецепты", route: "recipes" },
      { id: 9, label: "Отзывы", route: "reviews" },
    ],

    is_open_auth: false,
    modal_components: [],
    modal_select: "ModalAuth",
    modal_changed: "ModalAuth",
    is_changed: false,
    is_error: false,
  }),

  computed: {
    ...mapGetters({
      is_auth: "auth/is_auth",
      user: "auth/user",
      cart: "cart/cart",
      call_auth: "auth/call_auth",
      width_page: "utils/width_page",
    }),

    is_admin() {
      return this.user.role === "admin";
    },

    cart_counter() {
      if (this.cart.cart_code && this.cart.items) {
        return this.cart.items.reduce(
          (acc, current) => acc + current.amount,
          0
        );
      } else {
        return 0;
      }
    },
  },

  watch: {
    is_open_auth(value) {
      if (!value) {
        setTimeout(() => {
          this.modal_select = "ModalAuth";
          this.modal_changed = "ModalAuth";
          this.is_changed = false;
        }, 300);
      }
    },
    is_error(value) {
      if (value) {
        setTimeout(() => {
          this.is_error = false;
        }, 300);
      }
    },
    call_auth() {
      this.is_open_auth = true;
    },
  },

  methods: {
    changeRoute(route) {
      this.$router.push({ name: route });
    },

    commandHandler(route) {
      this.changeRoute(route);
    },

    openAuth(e) {
      e.stopPropagation();
      this.modal_select = "ModalAuth";
      this.is_open_auth = true;
    },

    changeModal(modal_changed) {
      this.modal_changed = modal_changed;
      this.is_changed = true;
      setTimeout(() => {
        this.modal_select = modal_changed;
      }, 300);
    },

    closeModal() {
      this.is_open_auth = false;
      this.$store.commit("auth/UPDATE_PHONE", null);
      this.$store.commit("auth/UPDATE_SERVICE", null);
      this.$store.commit("auth/UPDATE_REGISTRATION_FORM", null);
    },

    errorHandler() {
      this.is_error = true;
    },
  },
};
</script>
