<template>
  <div
    :class="[
      'v-input',
      `v-input--${size}`,
      { _error: errors.length, _icon: icon },
    ]"
  >
    <div v-if="label" class="v-input__label-container">
      <label class="v-input__label">{{ label }}</label>
      <span v-if="required" class="_required">*</span>
      <v-tooltip
        v-if="tooltip"
        class="v-input__tooltip"
        :content="tooltip"
        placement="top"
      />
    </div>
    <div class="v-input__input-container">
      <input
        ref="input"
        class="v-input__custom-input"
        :class="{ _disabled: disabled, _password: type === 'password' }"
        :type="accepted_type"
        v-model="valueSetter"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="new-password"
        v-mask="mask"
        @change="$emit('change')"
        @blur="$emit('blur')"
      />
      <button
        v-if="type === 'password'"
        class="v-input__password-btn"
        type="button"
        @click="show_password = !show_password"
      >
        <v-icon
          :name="show_password ? 'icon_hide_password' : 'icon_show_password'"
          width="28"
        />
      </button>
      <v-icon v-if="icon" class="v-input__input-icon" :name="icon" width="24" />
    </div>
    <div class="v-input__errors-container" v-auto-animate>
      <span v-for="(error, i) in errors" :key="error + i">{{ error }}</span>
    </div>
    <slot />
  </div>
</template>

<script>
import VTooltip from "@/components/ui-kit/v-tooltip";
import VIcon from "@/components/icon/v-icon";

export default {
  name: "v-input",
  components: { VIcon, VTooltip },
  props: {
    value: {
      required: true,
    },
    mode: {
      type: String,
      default: "default", // default | round
    },
    size: {
      type: String,
      default: "normal", // normal | small
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text", // text
    },
    maxlength: {
      type: String,
      default: "4000",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    focus: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    trim: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    show_password: false,
  }),

  computed: {
    valueSetter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", this.trim ? value.trim() : value);
      },
    },

    accepted_type() {
      if (this.type === "password" && this.show_password) {
        return "text";
      } else if (this.type === "password" && this.show_password === false) {
        return "password";
      } else {
        return this.type;
      }
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
};
</script>
