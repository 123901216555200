<template>
  <div
    class="app-modal__container"
    :class="{
      'animate__animated animate__fadeInRight animate__faster':
        modal_type === 'ModalCode' && is_changed,
      'animate__animated animate__fadeOutLeft animate__faster':
        modal_type !== 'ModalCode' && is_changed,
      'animate__animated animate__zoomOutDown': auth_success,
    }"
    v-auto-animate
  >
    <button class="app-modal__close" @click="$emit('close')">
      <v-icon name="icon_close" width="24" />
    </button>

    <h2 class="app-modal__title">Подтверждение</h2>
    <form class="form" @submit.prevent="handleSubmit">
      <v-input
        class="form__field form__field--small"
        v-bind="model.code"
        :label="service_text"
        :errors="code_errors"
        v-model="form.code"
      />
      <div class="app-modal__btn-group">
        <v-button type="submit">Продолжить</v-button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import VButton from "@/components/ui-kit/v-button";
import VIcon from "@/components/icon/v-icon";
import VInput from "@/components/ui-kit/v-input";
import { mapGetters } from "vuex";

export default {
  name: "modal-code",
  components: { VInput, VIcon, VButton },
  mixins: [validationMixin, notifications],

  props: {
    is_changed: {
      type: Boolean,
      default: false,
    },
    modal_type: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    auth_success: false,

    form: {
      code: "",
    },

    model: {
      code: {
        type: "text",
        placeholder: "ХХХХ",
        mask: "####",
        focus: true,
      },
    },
  }),

  validations: {
    form: {
      code: { required },
    },
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      service: "auth/service",
    }),

    service_text() {
      switch (this.service) {
        case "sms":
          return "Код подтвержения из СМС";
        case "whatsapp":
          return "Код подтвержения из Whatsapp";
        default:
          return "Код подтвержения";
      }
    },

    code_errors() {
      const errors = [];
      if (!this.$v.form.code.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
  },

  methods: {
    async handleSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const params = {
            sms_code: this.form.code,
            sms_token: this.$store.getters["auth/sms_token"],
            cart_code: this.user.cart || "",
          };
          await this.$store.dispatch("auth/authorizationCode", params);
          await this.$store.dispatch("auth/getUser");
          await this.$store.dispatch("user/getLetters");
          if (this.user.cart) {
            const params1 = {
              cart_code: this.user.cart,
            };
            await this.$store.dispatch("cart/getCart", params1);
          } else {
            this.$store.commit("cart/CLEAR_CART");
          }
          this.auth_success = true;
          setTimeout(() => {
            this.$emit("close");
          }, 600);
        } catch (e) {
          this.$emit("error");
          this.renderAsyncErrors(e);
        }
      } else {
        this.$emit("error");
      }
    },
  },
};
</script>
