import axios from "../api";

export default {
  namespaced: true,

  state: {
    locality_list: [],
    city_list: [],
  },
  getters: {
    locality_list: (state) => state.locality_list,
    city_list: (state) => state.city_list,
  },
  mutations: {
    UPDATE_LOCALITY_LIST(state, payload) {
      state.locality_list = payload;
    },
    UPDATE_CITY_LIST(state, payload) {
      state.city_list = payload;
    },
  },
  actions: {
    async getLocalityList({ commit }, params) {
      const response = await axios.get("admin/suggest_address/", {
        params: {
          query: params.query,
          ...params.data,
        },
      });
      await commit("UPDATE_LOCALITY_LIST", response.data);
    },
    async getCityList({ commit }, params) {
      const response = await axios.get("delivery/cdek_search/", {
        params,
      });
      await commit("UPDATE_CITY_LIST", response.data);
    },
  },
};
