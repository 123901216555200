import axios from "../api";

export default {
  namespaced: true,

  state: {
    products: [],
    orders: [],
    is_more: true,
    receipts: [],
    saved_receipts: [],
    is_more_receipts: true,
    mail_history: [],
    is_more_mail_history: true,
  },
  getters: {
    products: (state) => state.products,
    orders: (state) => state.orders,
    mail_history: (state) => state.mail_history,
    is_more_mail_history: (state) => state.is_more_mail_history,
    receipts: (state) => state.receipts,
    is_more_receipts: (state) => state.is_more_receipts,
    saved_receipts: (state) => state.saved_receipts,
    is_more: (state) => state.is_more,
  },
  mutations: {
    INIT_PRODUCTS: (state, payload) => {
      state.products = payload;
    },
    UPDATE_PRODUCTS: (state, payload) => {
      state.products = [...state.products, ...payload];
    },
    UPDATE_CURRENT_PRODUCT: (state, payload) => {
      const products = state.products.map((el) => {
        if (el.code === payload.code) {
          return payload;
        } else {
          return el;
        }
      });
      state.products = products;
    },
    UPDATE_IS_MORE: (state, payload) => {
      state.is_more = payload;
    },

    INIT_ORDERS: (state, payload) => {
      state.orders = payload;
    },
    UPDATE_ORDERS: (state, payload) => {
      state.orders = [...state.orders, ...payload];
    },

    INIT_RECEIPTS: (state, payload) => {
      state.receipts = payload;
      state.is_more_receipts = payload.length === 20;
    },
    UPDATE_RECEIPTS: (state, payload) => {
      state.receipts = [...state.receipts, ...payload];
      state.is_more_receipts = payload.length === 20;
    },
    SAVE_RECEIPTS: (state, payload) => {
      state.saved_receipts = payload;
    },
    CLEAR_RECEIPTS: (state) => {
      state.saved_receipts = [];
    },
    REMOVE_SAVE_RECEIPTS: (state, payload) => {
      state.saved_receipts = state.saved_receipts.filter(
        (el) => el.phone !== payload
      );
    },

    INIT_MAIL_HISTORY: (state, payload) => {
      state.mail_history = payload;
      state.is_more_mail_history = payload.length === 20;
    },
    UPDATE_MAIL_HISTORY: (state, payload) => {
      state.mail_history = [...state.mail_history, ...payload];
      state.is_more_mail_history = payload.length === 20;
    },
  },
  actions: {
    async getProducts({ commit }, params) {
      const response = await axios.get("admin/store/", params);
      await commit("INIT_PRODUCTS", response.data);
      await commit("UPDATE_IS_MORE", response.data.length === 20);
    },
    async getProductsMore({ commit }, params) {
      const response = await axios.get("admin/store/", params);
      await commit("UPDATE_PRODUCTS", response.data);
      await commit("UPDATE_IS_MORE", response.data.length === 20);
    },
    async changeProduct(_, params) {
      await axios.post("admin/change_field/", params);
    },
    async getCurrentProduct({ commit }, params) {
      const response = await axios.get("catalog/product_details/", { params });
      await commit("UPDATE_CURRENT_PRODUCT", response.data);
    },

    async getOrders({ commit }, params) {
      const response = await axios.get("admin/orders/", params);
      await commit("INIT_ORDERS", response.data);
      await commit("UPDATE_IS_MORE", response.data.length === 20);
    },
    async getOrdersMore({ commit }, params) {
      const response = await axios.get("admin/orders/", params);
      await commit("UPDATE_ORDERS", response.data);
      await commit("UPDATE_IS_MORE", response.data.length === 20);
    },

    async getReceipts({ commit }, params) {
      const response = await axios.get("mail/receipts/", params);
      await commit("INIT_RECEIPTS", response.data);
    },
    async getReceiptsMore({ commit }, params) {
      const response = await axios.get("mail/receipts/", params);
      await commit("UPDATE_RECEIPTS", response.data);
    },

    async sendMail(_, params) {
      await axios.post("mail/create/", params);
    },

    async getMailHistory({ commit }, params) {
      const response = await axios.get("mail/history/", params);
      await commit("INIT_MAIL_HISTORY", response.data);
    },
    async getMailHistoryMore({ commit }, params) {
      const response = await axios.get("mail/history/", params);
      await commit("UPDATE_MAIL_HISTORY", response.data);
    },

    async uploadProductImage(_, params) {
      await axios.post(
        `admin/add_picture/?product_code=${params.id}`,
        params.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    async deleteProductImage(_, params) {
      await axios.post("admin/delete_picture/", params);
    },
    async sendTest(_, params) {
      await axios.post("admin/dbg/", params);
    },
  },
};
