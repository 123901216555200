var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"auto-animate",rawName:"v-auto-animate"}],staticClass:"app-modal__container",class:{
    'animate__animated animate__fadeInRight animate__faster':
      _vm.modal_type === 'ModalCode' && _vm.is_changed,
    'animate__animated animate__fadeOutLeft animate__faster':
      _vm.modal_type !== 'ModalCode' && _vm.is_changed,
    'animate__animated animate__zoomOutDown': _vm.auth_success,
  }},[_c('button',{staticClass:"app-modal__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"name":"icon_close","width":"24"}})],1),_c('h2',{staticClass:"app-modal__title"},[_vm._v("Подтверждение")]),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('v-input',_vm._b({staticClass:"form__field form__field--small",attrs:{"label":_vm.service_text,"errors":_vm.code_errors},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}},'v-input',_vm.model.code,false)),_c('div',{staticClass:"app-modal__btn-group"},[_c('v-button',{attrs:{"type":"submit"}},[_vm._v("Продолжить")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }