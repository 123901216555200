export default {
  namespaced: true,

  state: {
    scroll_position: 0,
    width_page: null,
    is_open_mobile_menu: false,
  },
  getters: {
    scroll_position: (state) => state.scroll_position,
    width_page: (state) => state.width_page,
    is_open_mobile_menu: (state) => state.is_open_mobile_menu,
  },
  mutations: {
    UPDATE_SCROLL_POSITION: (state, payload) => {
      state.scroll_position = payload;
    },
    UPDATE_WIDTH_PAGE: (state, payload) => {
      state.width_page = payload;
    },
    UPDATE_OPEN_MOBILE_MENU: (state, payload) => {
      state.is_open_mobile_menu = payload;
      document.body.style.overflow = payload ? "hidden" : null;
    },
  },
};
