<template>
  <div class="wrapper">
    <v-header />
    <main>
      <router-view />
    </main>
    <v-footer />
  </div>
</template>

<script>
import VHeader from "@/components/layout/main/v-header";
import VFooter from "@/components/layout/main/v-footer";

export default {
  name: "main-layout",
  components: { VFooter, VHeader },
};
</script>
