export default {
  methods: {
    renderAsyncErrors(errors) {
      if (errors.response) {
        const { data } = errors.response;
        if (typeof data === "object") {
          for (const error in data) {
            setTimeout(() => {
              this.showNotifyError(
                "Ошибка",
                Array.isArray(data[error])
                  ? data[error].join("\n")
                  : data[error]
              );
            }, 100);
          }
        } else {
          this.showNotifyError(
            "Ошибка",
            "Просим прощения, что то пошло не так. Мы вкурсе и работаем над решением проблемы!"
          );
        }
      } else {
        console.log(errors);
      }
    },

    showNotifySuccess(title, message) {
      this.$notify({
        title,
        message,
        type: "success",
      });
    },

    showNotifyWarning(title, message) {
      this.$notify({
        title,
        message,
        type: "warning",
      });
    },

    showNotifyError(title, message) {
      this.$notify.error({
        title,
        message,
      });
    },
  },
};
