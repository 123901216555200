import axios from "../api";

export default {
  namespaced: true,

  state: {
    products: {},
    product_categories: [],
    special_products: [],
    product: {},
    is_more: true,
    product_params: null,
  },
  getters: {
    products: (state) => state.products,
    product_categories: (state) => state.product_categories,
    special_products: (state) => state.special_products,
    product: (state) => state.product,
    is_more: (state) => state.is_more,
    product_params: (state) => state.product_params,
  },
  mutations: {
    INIT_PRODUCTS: (state, payload) => {
      state.products = payload;
    },
    INIT_PRODUCT_CATEGORIES: (state, payload) => {
      state.product_categories = payload;
    },
    UPDATE_PRODUCTS: (state, payload) => {
      state.products = [...state.products, ...payload];
    },
    UPDATE_SPECIAL_PRODUCTS: (state, payload) => {
      state.special_products = payload;
    },
    UPDATE_PRODUCT: (state, payload) => {
      state.product = payload;
    },
    UPDATE_IS_NEXT: (state, payload) => {
      state.is_more = payload;
    },
    UPDATE_PRODUCT_PARAMS: (state, payload) => {
      state.product_params = payload;
    },
  },
  actions: {
    async getProducts({ commit }, params) {
      const response = await axios.get("catalog/", params);
      await commit("INIT_PRODUCTS", response.data);
      await commit("UPDATE_IS_NEXT", response.data.length === 12);
    },
    async getProductCategories({ commit }) {
      const response = await axios.get("catalog/categories/");
      await commit("INIT_PRODUCT_CATEGORIES", response.data);
    },
    async getProductsMore({ commit }, params) {
      const response = await axios.get("catalog/", params);
      await commit("UPDATE_PRODUCTS", response.data);
      await commit("UPDATE_IS_NEXT", response.data.length === 12);
    },
    async getSpecialProducts({ commit }, params) {
      const response = await axios.get("catalog/", params);
      await commit("UPDATE_SPECIAL_PRODUCTS", response.data);
    },
    async getProduct({ commit }, params) {
      const response = await axios.get("catalog/product_details/", params);
      await commit("UPDATE_PRODUCT", response.data);
    },
    async getContributions({ commit }, params) {
      const response = await axios.get("catalog/contributions/", params);
      await commit("INIT_PRODUCTS", response.data);
      await commit("UPDATE_IS_NEXT", response.data.length === 20);
    },
  },
};
