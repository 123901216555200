<template>
  <div class="wrapper wrapper--admin">
    <v-header />
    <main>
      <v-sidebar />
      <div class="content">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import VHeader from "@/components/layout/admin/v-header";
import VSidebar from "@/components/layout/admin/v-sidebar";
export default {
  name: "admin-layout",
  components: { VSidebar, VHeader },
};
</script>
