import axios from "../api";

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem("token") || "",
    refresh: localStorage.getItem("refresh") || "",
    user: JSON.parse(localStorage.getItem("user")) || {},
    call_auth: false,
    registration_form: null,
    phone: null,
    service: null,
    sms_token: null,
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    is_auth: (state) => !!state.token,
    call_auth: (state) => state.call_auth,
    registration_form: (state) => state.registration_form,
    phone: (state) => state.phone,
    service: (state) => state.service,
    sms_token: (state) => state.sms_token,
  },
  mutations: {
    AUTH_SUCCESS: (state, { access_token, refresh_token }) => {
      state.token = access_token;
      state.refresh = refresh_token;
      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh", refresh_token);
    },
    UPDATE_ACCESS_TOKEN: (state, { access_token }) => {
      state.token = access_token;
      localStorage.setItem("token", access_token);
    },
    UPDATE_USER: (state, payload) => {
      state.user = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },
    AUTH_LOGOUT: (state) => {
      state.token = "";
      state.refresh = "";
      state.user = {};
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("user");
    },
    CALL_AUTH: (state) => {
      state.call_auth = !state.call_auth;
    },
    UPDATE_REGISTRATION_FORM: (state, payload) => {
      state.registration_form = payload;
    },
    UPDATE_PHONE: (state, payload) => {
      state.phone = payload;
    },
    UPDATE_SERVICE: (state, payload) => {
      state.service = payload;
    },
    UPDATE_SMS_TOKEN: (state, payload) => {
      state.sms_token = payload;
    },
    UPDATE_TEMP_USER: (state, payload) => {
      state.user = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },
  },
  actions: {
    async authorization({ commit }, params) {
      const response = await axios.post("admin/login/", params);
      await commit("AUTH_SUCCESS", response.data);
    },
    async authorizationPhone({ commit }, params) {
      const response = await axios.post("auth/login/", params);
      await commit("UPDATE_SMS_TOKEN", response.data.sms_token);
    },
    async authorizationCode({ commit }, params) {
      const response = await axios.post("auth/code/", params);
      await commit("AUTH_SUCCESS", response.data);
    },
    async updateAccessToken({ commit }) {
      const response = await axios.post("auth/refresh_token/");
      await commit("UPDATE_ACCESS_TOKEN", response.data);
    },
    async getUser({ commit }) {
      const response = await axios.get("auth/get_user/");
      await commit("UPDATE_USER", response.data);
    },
    async registration({ commit }, params) {
      const response = await axios.post("auth/register/", params);
      await commit("UPDATE_SMS_TOKEN", response.data.sms_token);
    },
    logout: ({ commit }) => {
      commit("AUTH_LOGOUT");
    },
  },
};
