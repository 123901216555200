var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'v-button',
    `v-button--${_vm.mode}`,
    `v-button--${_vm.size}`,
    `v-button--${_vm.weight}`,
    { '_icon-right': _vm.iconRight, '_icon-left': _vm.iconLeft },
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }