import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import Axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import "animate.css";
import "./assets/styles/main.scss";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/ru-RU";
import autoAnimate from "@formkit/auto-animate";
import VueMask from "v-mask";
import VueAwesomeSwiper from "vue-awesome-swiper";
import Editor from "vue-editor-js/src/index";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import SwiperClass, {
  Navigation,
  Pagination,
  Keyboard,
  Thumbs,
  Autoplay,
} from "swiper";

import "swiper/swiper-bundle.css";

SwiperClass.use([Navigation, Pagination, Keyboard, Thumbs, Autoplay]);
Vue.use(VueAwesomeSwiper);

Vue.use(Editor);

Vue.use(VueTelInput);

Vue.directive("auto-animate", {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value);
  },
});

Vue.use(Vuelidate);
Vue.use(ElementUI, { locale });
Vue.use(VueMask);
Vue.prototype.$http = Axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
