<template>
  <header class="header header--admin">
    <div class="header__container">
      <v-button
        class="header__go-back go-back"
        mode="transparent"
        icon-left
        @click="$router.push({ name: 'main' })"
      >
        <v-icon name="icon_user" width="24" />
        Вернуться в режим пользователя
      </v-button>
      <v-user v-if="is_auth && user.role === 'admin'" class="header__user" />
    </div>
  </header>
</template>

<script>
import VButton from "@/components/ui-kit/v-button";
import VUser from "@/components/ui-kit/v-user";
import VIcon from "@/components/icon/v-icon";
import { mapGetters } from "vuex";

export default {
  name: "v-header",
  components: { VIcon, VUser, VButton },

  computed: {
    ...mapGetters({
      is_auth: "auth/is_auth",
      user: "auth/user",
    }),
  },
};
</script>
