<template>
  <div
    class="app-modal__container"
    :class="{
      'animate__animated animate__fadeInRight animate__faster':
        modal_type === 'ModalAuth' && is_changed,
      'animate__animated animate__fadeOutLeft animate__faster':
        modal_type !== 'ModalAuth' && is_changed,
    }"
    v-auto-animate
  >
    <button class="app-modal__close" @click="$emit('close')">
      <v-icon name="icon_close" width="24" />
    </button>

    <h2 class="app-modal__title">Войти</h2>
    <form class="form" @submit.prevent="handleSubmit">
      <v-phone-input-v2
        class="form__field form__field--small"
        v-model="form.phone"
        v-bind="model.phone"
        :errors="phone_errors"
      />
      <div class="app-modal__btn-group">
        <v-button type="submit">Войти</v-button>
        <v-button @click="changeModal('ModalRegistration')" mode="transparent">
          Нет аккаунта? Зарегистрируйтесь
        </v-button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import VButton from "@/components/ui-kit/v-button";
import VIcon from "@/components/icon/v-icon";
import VPhoneInputV2 from "@/components/ui-kit/v-phone-input-v2.vue";

export default {
  name: "modal-auth",
  components: { VPhoneInputV2, VIcon, VButton },
  mixins: [validationMixin, notifications],

  props: {
    is_changed: {
      type: Boolean,
      default: false,
    },
    modal_type: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    form: {
      phone: "",
    },

    model: {
      phone: {
        label: "Номер телефона",
        type: "text",
        placeholder: "+7 (---) --- -- --",
        focus: true,
      },
    },
  }),

  validations: {
    form: {
      phone: { required },
    },
  },

  computed: {
    phone_errors() {
      const errors = [];
      if (!this.$v.form.phone.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
  },

  methods: {
    handleSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.$store.commit("auth/UPDATE_PHONE", this.form.phone);
          this.changeModal("ModalAuthChoice");
        } catch (e) {
          this.$emit("error");
          this.renderAsyncErrors(e);
        }
      } else {
        this.$emit("error");
      }
    },

    changeModal(modal) {
      this.$emit("changeModal", modal);
    },
  },
};
</script>
