import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import products from "@/store/modules/products";
import admin from "@/store/modules/admin";
import cart from "@/store/modules/cart";
import user from "@/store/modules/user";
import docs from "@/store/modules/docs";
import utils from "@/store/modules/utils";
import common from "@/store/modules/common";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    admin,
    products,
    cart,
    user,
    utils,
    docs,
    common,
  },
});
