<template>
  <div class="v-user">
    <div class="v-user__wrapper">
      <el-dropdown
        v-if="is_auth && user.public_id"
        class="v-user__authorized"
        trigger="click"
        @command="commandHandler"
      >
        <button class="v-user__container">
          <span v-if="unread_letters" class="notification-circle" />
          <span class="v-user__avatar">
            <span>{{ user_name[0] }}</span>
          </span>
          <span class="v-user__name">{{ user_name }}</span>
          <v-icon name="icon_select" width="16" fill="#00395C" />
        </button>
        <el-dropdown-menu class="v-user__menu" slot="dropdown">
          <el-dropdown-item v-if="is_admin" command="enter">
            Режим админа
          </el-dropdown-item>
          <el-dropdown-item command="profile-user">Профиль</el-dropdown-item>
          <el-dropdown-item command="profile-orders"
            >Мои заказы</el-dropdown-item
          >
          <el-dropdown-item command="profile-letters">
            Уведомления ({{ unread_letters }})
          </el-dropdown-item>
          <el-dropdown-item command="logout">Выйти</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VIcon from "@/components/icon/v-icon";

export default {
  name: "v-user",
  components: {
    VIcon,
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      is_auth: "auth/is_auth",
      user: "auth/user",
      call_auth: "auth/call_auth",
      unread_letters: "user/unread_letters",
    }),

    is_admin() {
      return this.user.role === "admin";
    },

    user_name() {
      if (this.is_admin) {
        return "Администратор";
      } else {
        return this.user.first_name || "Пользователь";
      }
    },
  },

  methods: {
    commandHandler(command) {
      switch (command) {
        case "enter":
          if (this.$route.name !== "admin-store") {
            this.$router.push({ name: "admin-store" });
          }
          break;
        case "profile-user":
          if (this.$route.name !== "profile-user") {
            this.$router.push({ name: "profile-user" });
          }
          break;
        case "profile-orders":
          if (this.$route.name !== "profile-orders") {
            this.$router.push({ name: "profile-orders" });
          }
          break;
        case "profile-letters":
          if (this.$route.name !== "profile-letters") {
            this.$router.push({ name: "profile-letters" });
          }
          break;
        case "logout":
          this.$store.dispatch("auth/logout");
          this.$store.commit("cart/CLEAR_CART");
          this.$router.push({ name: "main" });
          break;
      }
    },
  },
};
</script>
